<template>
  <div class="dump-edit">
    <UpdateForm
      :data="data"
      :columnsProjectDump="columnsProjectDump"
      v-on:handleVehicle="handleVehicle"
      v-on:onsubmit="onSubmit"
    />
  </div>
</template>

<script>
import { Toast, Notify } from 'vant';
import UpdateForm from "./components/UpdateForm.vue";

export default {
  components: { UpdateForm },
  data() {
    return {
      data: {},
      columnsProjectDump: [],
    };
  },
  mounted() {
    const { $route } = this;
    this.loadView({ id: $route.query.id });

    let columnsProjectDump = JSON.parse(sessionStorage.getItem('projectDump')) || [];
    if (columnsProjectDump.length > 0) {
      this.columnsProjectDump = columnsProjectDump;
    }
    else this.loadProject();
  },
  methods: {
    loadView(params) {
      this.$request.queryWaybillDetail({ ...params }, response => {
        if (response.status === 200) {
          const result = response.data;
          this.data = result;
        }
      })
    },
    handleVehicle({ license, alias }) {
      this.loadVehicle({ license, alias });
    },
    loadVehicle({ license, alias }) {
      const initVehicle = result => {
        if (result) {
          const { id, license, alias } = result;
          this.data = { ...this.data, license, alias };
          this.companyVisible = true;
          this.externalVisible = false;

          this.$request.queryDriver({ id: result.id }, response =>{
            if (response.status == 200) {
              if (response.data.driver) {
                const { id, driverName, userId } = response.data.driver;
                this.params.driverId = id;
                this.params.driverName = driverName;
                this.params.driverUserId = userId;
                this.data = { ...this.data, driverName };
              }
            }
          });
        } else {
          this.data = { ...this.data, license, alias };
          this.params.driverId = '';
          this.params.driverName = '';
          this.params.driverUserId = '';
          this.companyVisible = false;
          this.externalVisible = true;
        }
      }

      if (!sessionStorage.vehicle) {
        this.$request.queryVehicle({}, response => {
          if (response.status === 200) {
            const result = response.data;
            sessionStorage.vehicle = JSON.stringify(result);

            let data = result.filter(item => item.license == license || item.alias == alias);
            initVehicle(data[0]);
          }
        })
      }else{
        const result = JSON.parse(sessionStorage.getItem('vehicle')) || [];
        let data = result.filter(item => item.license == license || item.alias == alias);
        initVehicle(data[0]);
      }
    },
    loadProject() {
      Toast.loading({
        message: '加载中',
        forbidClick: true,
        duration: 0,
      });
      this.$request.queryProject({}, response => {
        if (response.status === 200) {
          const result = response.data;
          let columnsProjectDump = [];
          result.map(item => {
            item.text = item.name;

            if (item.type == 1 || item.type == 2) {
              columnsProjectDump.push(item);
            }
          })
          sessionStorage.projectDump = JSON.stringify(columnsProjectDump);
          this.columnsProjectDump = columnsProjectDump;

          this.data = { ...this.data }
          Toast.clear();
        }
      })
    },
    onSubmit(values) {
      const { $store, $route } = this;
      values.id = $route.query.id*1;
      
      if ($store.state.loading) return;
      $store.commit('setLoading', { loading: true });

      this.$request.updateWaybill({ ...values }, response => {
        if (response.status === 200) {
          const result = response.data;
          $store.commit('setLoading', { loading: false });
          
          if (result.code === 1) {
            Notify({ type: 'success', message: '保存成功' });
            this.$router.replace({ path: '/complate' });
          }else{
            Notify({ type: 'warning', message: result.msg });
          }
        }
      })
    },
  },
};
</script>