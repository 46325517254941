<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="license"
      label="车牌号"
      placeholder="请输入车牌号"
      maxlength="7"
    />

    <van-field
      v-model="alias"
      label="大号"
      placeholder="请输入大号"
    />

    <van-field
      v-model="driverName"
      label="司机"
      placeholder="请输入司机"
    />

    <van-field
      readonly
      clickable
      name="dumpSiteId"
      v-model="dumpSite"
      label="卸点名称"
      placeholder="点击选择卸点名称"
      is-link
      arrow-direction="down"
      required
      :rules="[{ required: true, message: '卸点名称不能为空' }]"
      @click="showDumpSite = true"
    />
    <van-popup
      v-model:show="showDumpSite"
      position="bottom"
    >
      <Picker
        :columns="columnsProjectDump"
        v-on:change="value => columnActiveDump = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmDumpSite"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <van-field
      name="radio"
      label="是否短驳"
    >
      <template #input>
        <van-radio-group
          v-model="radio"
          direction="horizontal"
        >
          <van-radio
            name="1"
            shape="square"
          >是</van-radio>
          <van-radio
            name="2"
            shape="square"
          >否</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    
    <van-field name="vehicleType" label="车辆类型">
      <template #input>
        <van-radio-group v-model="vehicleType" direction="horizontal">
          <van-radio name="1" shape="square">小车</van-radio>
          <van-radio name="2" shape="square">大车</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-field v-model="weightInit" type="number" label="重车" />
    <van-field v-model="weight" type="number" label="净重" disabled />

    <div style="margin: 16px;">
      <van-button
        :loading="$store.state.loading"
        round
        block
        type="primary"
        native-type="submit"
      >
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import Picker from "../../../components/Picker.vue";

export default {
  props: ["data", "columnsProjectDump"],
  components: { Picker },
  data() {
    return {
      license: "",
      alias: "",
      driverName: "",
      radio: "2",
      readyLicense: false,

      dumpSite: "",
      dumpSiteId: undefined,
      showDumpSite: false,
      columnActiveDump: {},
      loadSiteId: undefined,
      vehicleType: '',
      weightInit: undefined,
      weight: undefined,
      weightValue: { 1: 18, 2: 20 },
    };
  },
  mounted() {
    const { $watch, $emit, weightValue } = this;

    $watch("data", newValue => {
      const { license, alias, driverName, dumpSiteId, vehicleType, weight } = newValue;
      if (this.license == license) {
        this.readyLicense = true;
      }

      this.license = license;
      this.alias = alias;
      this.driverName = driverName;
      this.dumpSiteId = dumpSiteId;
      this.vehicleType = vehicleType + '';
      this.weightInit = weight/100 + weightValue[vehicleType];
      this.weight = weight/100;
    });

    $watch("license", newValue => {
      const { readyLicense } = this;
      if (newValue && newValue.length == 7 && !readyLicense) {
        $emit("handleVehicle", { license: newValue });
      }else{
        this.readyLicense = false;
      }
    });

    $watch("alias", newValue => {
      if (newValue) {
        setTimeout(() => {
          const { alias, readyAlias } = this;
          if (newValue == alias) {
            $emit("handleVehicle", { alias: newValue });
          }
        }, 2000);
      }
    });

    $watch("columnsProjectDump", newValue => {
      const { dumpSiteId } = this;
      newValue.map(item => {
        if (item.id == dumpSiteId) {
          this.dumpSite = item.name;
        }
      });
    });

    $watch("radio", newValue => {
      if (newValue == 1) {
        this.loadSiteId = this.columnActiveDump.id;
      } else if (newValue == 2) {
        this.loadSiteId = undefined;
      }
    });

    $watch('vehicleType', newValue => {
      const { weightInit, weightValue } = this;
      if (newValue && weightInit) this.weight = weightInit - weightValue[newValue];
    })

    $watch('weightInit', newValue => {
      const { vehicleType, weightValue } = this;
      let value = parseInt(newValue*10);
      this.weightInit = value > 700 ? 70 : value/10;

      if (vehicleType) this.weight = this.weightInit - weightValue[vehicleType];
    })
  },
  methods: {
    onConfirmDumpSite() {
      const {
        columnActiveDump: { id, text }
      } = this;
      this.dumpSite = text;
      this.dumpSiteId = id;
      this.showDumpSite = false;
    },
    onSubmit() {
      const {
        data: { id, endImg, license, alias },
        loadSiteId,
        dumpSiteId,
        driverName,
        vehicleType,
        weight,
      } = this;

      this.$emit("onsubmit", {
        id,
        endImg,
        license,
        alias,
        driverName,
        dumpSiteId,
        loadSiteId,
        vehicleType,
        weight: weight*100,
      });
    }
  }
};
</script>